<template>
  <div class="pps-icon">
    <svg
      t="1713442769179"
      class="icon"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="1390"
      width="20"
      height="20"
    >
      <path
        d="M513 529.3c-118.8 0-215.5-96.7-215.5-215.5S394.2 98.2 513 98.2s215.5 96.7 215.5 215.5S631.8 529.3 513 529.3z m0-380.5c-90.9 0-164.9 74-164.9 164.9s74 164.9 164.9 164.9 164.9-74 164.9-164.9-74-164.9-164.9-164.9z"
        fill="#231815"
        p-id="1391"
      ></path>
      <path
        d="M796.3 876.6H229.7c-25.4 0-49.2-11.3-65.4-30.9-15.4-18.7-21.7-42.9-17.1-66.2C181.3 605.2 335.1 478.7 513 478.7c177.8 0 331.7 126.5 365.7 300.8 4.6 23.4-1.7 47.5-17.1 66.2-16.1 19.7-40 30.9-65.3 30.9zM513 529.3c-153.7 0-286.7 109.3-316.1 259.9-2.3 11.6 3.1 20.3 6.5 24.4 6.5 8 16.1 12.5 26.3 12.5h566.5c10.2 0 19.8-4.5 26.4-12.5 3.4-4.1 8.8-12.8 6.5-24.4C799.7 638.6 666.7 529.3 513 529.3z"
        fill="#231815"
        p-id="1392"
      ></path>
    </svg>
  </div>
</template>

<script>
export default { name: 'pps-icon-admin' };
</script>
