<template>
  <div class="pps-icon">
    <svg
      t="1689691213164"
      class="icon"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="3989"
      width="20"
      height="20"
    >
      <path
        d="M456.533333 104.533333c-187.733333 0-341.333333 153.6-341.333333 341.333334s153.6 341.333333 341.333333 341.333333 341.333333-153.6 341.333334-341.333333-153.6-341.333333-341.333334-341.333334z m0 618.666667c-151.466667 0-277.333333-123.733333-277.333333-277.333333s125.866667-277.333333 277.333333-277.333334 277.333333 123.733333 277.333334 277.333334-125.866667 277.333333-277.333334 277.333333zM902.4 883.2l-29.866667 29.866667c-8.533333 8.533333-21.333333 8.533333-29.866666 0l-192-192 59.733333-59.733334 192 192c8.533333 8.533333 8.533333 21.333333 0 29.866667z"
        fill="#2e2e2e"
        p-id="3990"
      ></path>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'pps-icon-search'
};
</script>
