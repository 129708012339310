<template>
  <div class="pps-icon">
    <svg
      t="1713502126052"
      class="icon"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="1602"
      width="20"
      height="20"
    >
      <path
        d="M733.5 877.9h-443c-54.3 0-98.6-44.2-98.6-98.6V492.5c0-54.4 44.3-98.6 98.6-98.6h443c54.3 0 98.6 44.2 98.6 98.6v286.8c0 54.4-44.2 98.6-98.6 98.6z m-443-424.7c-21.7 0-39.3 17.6-39.3 39.3v286.8c0 21.7 17.6 39.3 39.3 39.3h443c21.7 0 39.3-17.6 39.3-39.3V492.5c0-21.7-17.6-39.3-39.3-39.3h-443z"
        fill="#231815"
        p-id="1603"
      ></path>
      <path
        d="M669.7 453.2H354.3c-23.5 0-42.6-19.1-42.6-42.7V337c0-105.2 85.6-190.9 190.9-190.9h19c105.2 0 190.9 85.6 190.9 190.9v73.6c-0.1 23.4-19.3 42.6-42.8 42.6zM371 393.9h282V337c0-72.5-59-131.5-131.5-131.5h-19C430 205.4 371 264.5 371 337v56.9zM512 634.7c-31 0-56.2-25.2-56.2-56.2 0-31 25.2-56.2 56.2-56.2 30.9 0 56.1 25.2 56.1 56.2 0 31-25.2 56.2-56.1 56.2z m0-59.3l-3.2 3.2c0 1.7 1.4 3.1 3.2 3.1v-6.3z"
        fill="#231815"
        p-id="1604"
      ></path>
      <path
        d="M512 725.9c-16.4 0-29.7-13.3-29.7-29.7V567.9c0-16.4 13.3-29.7 29.7-29.7s29.7 13.3 29.7 29.7v128.3c0 16.4-13.3 29.7-29.7 29.7z"
        fill="#231815"
        p-id="1605"
      ></path>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'pps-icon-lock'
};
</script>
