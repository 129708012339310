<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<style scoped lang="less">
#app {
  height: 100%;
}
</style>

<script>
export default {
  name: 'App',
  methods: {}
};
</script>
