<template>
  <svg
    t="1721583766095"
    class="icon"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    p-id="3630"
    width="24"
    height="24"
  >
    <path
      d="M512 102.4C285.696 102.4 102.4 285.696 102.4 512s183.296 409.6 409.6 409.6 409.6-183.296 409.6-409.6S738.304 102.4 512 102.4z m218.9312 409.6c0 28.2624-22.9376 51.2-51.2 51.2H344.2688c-28.2624 0-51.2-22.9376-51.2-51.2s22.9376-51.2 51.2-51.2h335.4624c28.2624 0 51.2 22.9376 51.2 51.2z"
      fill="#ff0000"
      p-id="3631"
    ></path>
  </svg>
</template>

<script>
export default {
  name: 'pps-icon-offline'
};
</script>
